import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="October 2023" />
		<NewsHeader />
		<h2>October 2023</h2>

		<h3 className="blogdate">
			Saturday, October 28, 2023
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">New music, new merch, and shows</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				First, I have a handful of shows happening this November and December,
				<span className="bold">in Canada</span>, and I would really love to see you out at one!
				It's easy to share the details with your friends;
				head over to <Link to="/events/">Shows</Link> and click on one 😎
			</p>
			<p>
				Second, I have new merch available. My new wintry-warm LP, <span className="italic"><Link to="/music/17/">Distant December</Link></span>,
				will be streaming as of December 1; however, I have <span className="bold">physical CDs</span> ready now at
				my <Link to="/merch/">Merch</Link> page and with me at shows.
				And various colours of a cool new <span className="bold">tee shirt</span>!
			</p>
			<p>
				Finally, the new music: the fabric of all this cloth. On November 3,
				my new track, <span className="italic"><Link to="/music/16/">Look at What You've Got</Link></span>,
				will be on your streaming release radar (you're following me on streaming, right?).
				This is a new winter/holiday song and is the first single off my new wintry-warm album LP mentioned above.
				If you're anxious and dig a little, you'll find that both the single and the new LP will be
				up on streaming already during the week leading up to either release date.
			</p>
			<p>
				You will do me a world of good if you share this news with friends and family—the ones that are into
				this sorta thing 😎
			</p>
			<p>
				I'll leave you with the following image—the cover of <span className="italic"><Link to="/music/17/">Distant December</Link></span>—which
				is a painting by <span className="italic">brasiliense</span> artist Paula Calderón.
				She did a great job!
				Also, there's me in one of my new tee shirts 😎
			</p>

			<div className="main--centerwrapper p">
				<StaticImage
				src="../../images/catalog artwork/JRIVEST17 distant december front.png"
				alt="Jesse Rivest - Distant December - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>

			<div className="main--centerwrapper p">
				<StaticImage
				src="../../images/merch/Distant December - tee shirts - my photos/20231028_120420.jpg"
				alt="Jesse Rivest - Distant December - merch image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				height={888}
				/>
			</div>

		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
